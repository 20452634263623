'use strict';

Gri.module({
  name: 'carousel-gamma',
  ieVersion: null,
  $el: $('.carousel-gamma'),
  container: '.carousel-gamma',
  fn: function () {

    const $this = this.$el;

    $this.owlCarousel({
      items: 5,
      nav: true,
      margin: 10,
      navText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>']
    });

  }
});
